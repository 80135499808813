
import { Options, Vue } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import formattedNPWP from "@/app/infrastructures/misc/common-library/FormattedNPWP";
import parseInputFloatToFloat from "@/app/infrastructures/misc/common-library/ParseInputFloatToFloat";
import convertPhoneNumber from "@/app/infrastructures/misc/common-library/ConvertPhoneNumber";
import { ClientRegistrationController } from "@/app/ui/controllers/ClientRegistrationController";
import { ClientManagementController } from "@/app/ui/controllers/ClientManagementController";
import debounce from "lodash/debounce";
import Checkbox from "primevue/checkbox";
import { PosController } from "@/app/ui/controllers/PosController";
import { BeneficiaryAccountController } from "@/app/ui/controllers/BeneficiaryAccountController";
import BankSection from "../../client-registration/component/bank-section.vue";
import { RequestListPartner } from "@/data/payload/api/PartnerApiRequest";
import TaxSection from "../../component/tax-section.vue";
import BillingSection from "../../component/billing-section.vue";
import BankSettlementSection from "../../component/bank-settlement-section.vue";
import {
  BankDetail,
  ClientBilling,
  ClientTax
} from "@/domain/entities/ClientManagement";
import { EditClientManagementBasicInformationRequest } from "@/data/payload/api/ClientManagementApiRequest";
import convertArrayToString from "@/app/infrastructures/misc/common-library/ConvertArrayToString";
import formatDateNumber from "@/app/infrastructures/misc/common-library/FormatDateNumber";
import { startCase } from "lodash";
import {
  clientCodConfigAmountData,
  clientPaymentMethodData
} from "@/domain/entities/ClientRegistration";
import { OptionsClass } from "@/domain/entities/MainApp";
import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";
import DetailDeliveryOrder from "@/app/ui/views/sales/clients/client-management/detail/detail-client-information/detail-delivery-order.vue";
import LpRadio from "@/app/ui/components/lp-radio/index.vue";

@Options({
  emits: ["update:show", "success"],
  components: {
    Checkbox,
    Title,
    BankSection,
    TaxSection,
    BillingSection,
    BankSettlementSection,
    DetailDeliveryOrder,
    LpRadio
  },
  props: {
    modelValue: {
      default: {}
    },
    show: {
      default: false,
      type: Boolean
    }
  }
})
export default class EditBasicInformation extends Vue {
  get isEnableCodOngkir() {
    return FlagsMedusa.config_cod_ongkir_enable.isEnabled();
  }

  props: any = this.$props;
  emit = this.$emit;
  modelValue = {
    clientType: "",
    clientId: 0,
    clientCode: "",
    clientName: "",
    clientCategory: "",
    clientAddress: "",
    clientDistrict: { name: "", code: "", cityCode: "" },
    clientCityCode: "",
    clientEmail: "",
    clientPhone: "",
    clientNpwp: "",
    clientContactName: "",
    clientContactJobTitle: "",
    clientContactEmail: "",
    clientContactPhone: "",
    clientBankAccountName: "",
    clientBankName: "",
    clientBankAccountNumber: "",
    clientBankEmail: "",
    clientIsCod: false,
    clientIsDeliveryOrder: false,
    clientParentName: "",
    clientParentPos: { name: "", id: 0 },
    clientParentPOSCommision: "",
    clientParentCommisionType: "",
    clientParentNumeratorCommision: "",
    clientOdooId: 0,
    clientCompanySize: "",
    clientTax: new ClientTax(),
    clientBilling: new ClientBilling(),
    bankSettlement: new BankDetail(),
    clientBranchDoPaymentType: ""
  };

  back = false;

  get isEnableCodBooking() {
    return FlagsMedusa.config_new_cod_booking_enable.isEnabled();
  }

  mounted() {
    this.fetchDistrictList("");
    this.fetchPOSList("");
  }

  // Validation
  formatPhoneNumber(value: string) {
    const newValue = value.replace(/^62|^0|[\D]+/g, "");
    return newValue.startsWith("0")
      ? newValue.slice(1, newValue.length)
      : newValue;
  }
  formatNPWP(value: string) {
    return formattedNPWP(value.replace(/[\D]+/g, ""));
  }
  formatRekening(value: string) {
    return value.replace(/[\D]+/g, "");
  }
  get errorFormEmail() {
    return !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.modelValue.clientEmail);
  }
  get errorFormContactEmail() {
    return !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
      this.modelValue.clientContactEmail
    );
  }
  get errorFormBankEmail() {
    return (
      this.modelValue.clientBankEmail !== "" &&
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.modelValue.clientBankEmail)
    );
  }

  get errorFormPhoneNumber() {
    return this.modelValue.clientPhone.length < 6;
  }
  get errorFormNPWP() {
    return this.modelValue.clientNpwp.replace(/[.|-]/g, "").length < 15;
  }
  get isEditDetailInvalid() {
    if (this.modelValue.clientType.toLowerCase() === "induk client") {
      return (
        this.modelValue.clientName &&
        this.modelValue.clientCategory &&
        this.modelValue.clientCompanySize &&
        this.modelValue.clientDistrict &&
        this.isFormValidTaxSection &&
        this.isFormValidBillingSection &&
        this.modelValue.clientAddress &&
        this.modelValue.clientDistrict &&
        this.modelValue.clientEmail &&
        this.modelValue.clientPhone &&
        this.modelValue.clientContactName &&
        this.modelValue.clientContactJobTitle &&
        this.modelValue.clientContactEmail &&
        this.modelValue.clientContactPhone &&
        !this.errorFormEmail &&
        !this.errorFormContactEmail &&
        !this.errorFormPhoneNumber
      );
    }
    return (
      this.modelValue.clientName &&
      this.modelValue.clientAddress &&
      this.modelValue.clientDistrict &&
      this.modelValue.clientEmail &&
      this.modelValue.clientPhone &&
      this.modelValue.clientParentName &&
      this.modelValue.clientParentPOSCommision !== "" &&
      this.modelValue.clientParentCommisionType &&
      this.modelValue.clientParentNumeratorCommision &&
      this.modelValue.clientContactName &&
      this.modelValue.clientContactJobTitle &&
      this.modelValue.clientContactEmail &&
      this.modelValue.clientContactPhone &&
      !this.errorFormEmail &&
      !this.errorFormContactEmail &&
      !this.errorFormPhoneNumber &&
      this.isCodMandatory &&
      !this.errorFormCommision.error &&
      !this.errorClientCodShipmentDiscount.error &&
      (this.modelValue.clientIsDeliveryOrder
        ? this.modelValue.clientBranchDoPaymentType
        : true)
    );
  }
  // modal
  showConfirmModal = false;
  showCloseConfirmModal = false;
  get isSuccessSubmit() {
    return ClientRegistrationController.succesEditBasicInformation;
  }
  setSuccessSubmit(val: boolean) {
    ClientManagementController.getDetail(this.modelValue.clientId);
    ClientRegistrationController.setSuccesEditBasicInformation(val);
  }

  // bank detail
  get bankDetail(): any {
    return ClientRegistrationController.bankDetail;
  }

  get bankDetailSettlement(): any {
    return ClientRegistrationController.bankDetailSettlement;
  }

  get clientTypeValue() {
    return this.modelValue.clientType.toLowerCase() === "induk client"
      ? "parent"
      : "branch";
  }

  async onSubmit() {
    this.showConfirmModal = false;
    const payload = new EditClientManagementBasicInformationRequest({
      id: this.modelValue.clientId,
      companyName: this.modelValue.clientName,
      category: this.modelValue.clientCategory,
      address: this.modelValue.clientAddress,
      districtCode: this.modelValue.clientDistrict?.code || "",
      phone: convertPhoneNumber(this.modelValue.clientPhone),
      email: this.modelValue.clientEmail,
      taxNumber:
        this.modelValue.clientType.toLowerCase() === "induk client"
          ? this.modelValue.clientTax.taxNumber
          : this.modelValue.clientNpwp,
      contactName: this.modelValue.clientContactName,
      contactJobTitle: this.modelValue.clientContactJobTitle,
      contactEmail: this.modelValue.clientContactEmail,
      contactPhone: convertPhoneNumber(this.modelValue.clientContactPhone),
      bankName: this.bankDetail.bankName.value || this.bankDetail.bankName,
      bankAccountName: this.bankDetail.accountName,
      bankAccountNumber: this.bankDetail.accountNo,
      bankAccountEmail: this.bankDetail.email,
      bankAccountLabel: this.bankDetail.bankLabel,
      isCod: this.modelValue.clientIsCod,
      isDfod: this.detailClient.clientIsDfod,
      isDo: this.modelValue.clientIsDeliveryOrder,
      partnerPosId: this.modelValue.clientParentPos
        ? this.modelValue.clientParentPos.id
        : 0,
      partnerPosQuantifierCommission: this.modelValue.clientParentCommisionType,
      partnerPosNumeratorCommission: this.modelValue
        .clientParentNumeratorCommision,
      partnerPosCommission: this.parseToNumber(
        this.modelValue.clientParentPOSCommision.toString()
      ),
      type: this.clientTypeValue,
      receiverName: this.detailClient.clientCodReject.clientCodName,
      receiverPhone: convertPhoneNumber(
        this.detailClient.clientCodReject.clientCodPhone
      ),
      receiverAddress: this.detailClient.clientCodReject.clientCodAddress,
      receiverDistrict:
        this.detailClient.clientCodReject.clientCodDistrict?.code || "",
      receiverCity:
        this.detailClient.clientCodReject.clientCodDistrict?.cityCode || "",
      codFee: this.parseToNumber(
        this.detailClient.clientCodReject.clientCodCommissionPercentage.toString()
      ),
      companySize: this.modelValue.clientCompanySize,
      isEfakturWithNik: this.modelValue.clientTax.isTaxPayer,
      nikId: this.modelValue.clientTax.NIK,
      nikName: this.modelValue.clientTax.NIKName,
      nikAddress: this.modelValue.clientTax.NIKAddress,
      taxpayerName: this.modelValue.clientTax.NIKName,
      taxpayerAddress: this.modelValue.clientTax.NIKAddress,
      billingWaNumber: convertPhoneNumber(
        this.modelValue.clientBilling.noWhatsApp
      ),
      billingEmail: convertArrayToString(
        this.modelValue.clientBilling.email,
        "name"
      ),
      billingScheduleSo: this.modelValue.clientBilling.billingSchedule.startsWith(
        "bi"
      )
        ? "Bi-Weekly"
        : `${startCase(this.modelValue.clientBilling.billingSchedule)}`,
      billingPaymentPeriod: `${this.modelValue.clientBilling.timeRangePayment} Days`,
      contractStartDate: formatDateNumber(
        this.modelValue.clientBilling.startDate
      ),
      contractEndDate: formatDateNumber(this.modelValue.clientBilling.endDate),
      vaBankNumber: this.bankDetailSettlement.accountNo ?? "",
      vaBankAccountName: this.bankDetailSettlement.accountName ?? "",
      vaBankName: this.bankDetailSettlement.bankName
        ? this.bankDetailSettlement.bankName?.name ||
          this.bankDetailSettlement.bankName
        : "",
      vaBankLabel: this.bankDetailSettlement.bankName
        ? `${this.bankDetailSettlement.bankName?.name?.toUpperCase() ||
            this.bankDetailSettlement.bankName.toUpperCase()} Virtual Account`
        : "",
      contractAttachment: this.modelValue.clientBilling.attachment,
      isReuploadContract: !!this.modelValue.clientBilling.attachment,
      clientPadFee: this.parseToNumber(this.detailClient.clientPadFeePercentage.toString()),
      paymentMethod: this.isClientParent
        ? this.detailClient.clientPaymentMethod?.value
        : "",
      clientCodConfigAmount: this.detailClient.clientCodConfigAmount.value,
      clientCodShipmentDiscount: this.parseToNumber(this.detailClient.clientCodShipmentDiscountPercentage.toString()),
      clientBranchDoPaymentType: this.modelValue.clientBranchDoPaymentType
    });
    this.submitPayload(payload);
  }

  formatNumber(value: string): number | string {
    return +value.replace(/,/g, ".") || "";
  }

  async submitPayload(payload: EditClientManagementBasicInformationRequest) {
    const resp = await ClientRegistrationController.editClientManagementBasicInformation(
      payload
    );
    if (resp) {
      this.$emit("success");
    }
  }

  get detailClient(): any {
    return ClientManagementController.detailClientManagement;
  }

  // district list
  fetchDistrictList = debounce((search: string) => {
    ClientRegistrationController.getDistrictList({
      search: search
    });
  }, 250);

  get districtList() {
    return ClientRegistrationController.districtList;
  }

  get loadingDistrictList() {
    return ClientRegistrationController.loadingDistrictList;
  }

  // pos list
  fetchPOSList = debounce((search: string) => {
    PosController.getPosList(
      new RequestListPartner({
        page: 1,
        limit: 10,
        search: search,
        cityCode: this.props.modelValue.clientCityCode,
        type: "pos",
        useLocation: true,
        useDefaultLocation: true
      })
    );
  });

  get posList() {
    return PosController.posList.data;
  }

  get loadingPosList() {
    return PosController.isLoading;
  }

  // kategori client
  get clientCategoryList() {
    const clientCategoryList: any[] = [
      {
        name: "Pilih kategori client",
        value: ""
      },
      {
        name: "Business to Business",
        value: "B2B"
      },
      {
        name: "Business to Customer",
        value: "B2C"
      },
      {
        name: "Customer to Customer",
        value: "C2C"
      },
      {
        name: "Small Medium Business",
        value: "SMB"
      }
    ];
    return clientCategoryList;
  }

  isOpenClientCategory = false;
  onOpenSelectClientCategory() {
    this.isOpenClientCategory = true;
  }

  onCloseSelectClientCategory() {
    this.isOpenClientCategory = false;
  }

  onSelectClientCategory(key: string, value: string) {
    this.onCloseSelectClientCategory();
    this.modelValue.clientCategory = value;
  }

  // pembilang komisi
  isOpenSelectNumeratorCommision = false;
  onOpenSelectNumeratorCommision() {
    this.isOpenSelectNumeratorCommision = true;
  }

  onCloseSelectNumeratorCommision() {
    this.isOpenSelectNumeratorCommision = false;
  }

  onSelectNumeratorCommision(key: string, value: string) {
    this.onCloseSelectNumeratorCommision();
    this.modelValue.clientParentNumeratorCommision = value;
  }

  get numeratorCommisionList() {
    const numeratorCommisionList: any[] = [
      {
        name: "Pilih pembilang komisi",
        value: ""
      },
      {
        name: "Harga Retail",
        value: "retail-rate"
      },
      {
        name: "Harga Client",
        value: "client-rate"
      }
    ];
    return numeratorCommisionList;
  }

  // Tipe Komisi
  isOpenCommissionType = false;
  onOpenSelectCommissionType() {
    this.isOpenCommissionType = true;
  }

  onCloseSelectCommissionType() {
    this.isOpenCommissionType = false;
  }

  onSelectCommissionType(key: string, value: string) {
    this.onCloseSelectCommissionType();
    this.modelValue.clientParentCommisionType = value;
  }

  get clientParentCommisionTypeList() {
    const clientParentCommisionTypeList: any[] = [
      {
        name: "Pilih Tipe Komisi",
        value: ""
      },
      {
        name: "Shipping Surcharge",
        value: "shipping_surcharge"
      },
      {
        name: "Publish Rate",
        value: "publish_rate"
      },
      {
        name: "Publish Rate + Shipping Surcharge",
        value: "publish_rate_shipping_surcharge"
      }
    ];
    return clientParentCommisionTypeList;
  }

  error = {
    companyName: false,
    email: false,
    phone: false,
    address: false,
    taxNumber: false,
    versionBasic: false,
    discount: false,
    priceRateStartDate: false,
    priceRateEndDate: false,
    versionName: false,
    archiveFile: false,
    accountBankNo: false,
    bankEmail: false,
    contactEmail: false,
    contactPhone: false,
    commission: false,
    codRejdistrict: false,
    codRejCommision: false,
    codRejPhone: false,
    emailBilling: false,
    NIKNumber: false,
    contactWhatsApp: false,
    invalidDate: false
  };

  get anyFormError() {
    let result = false;
    Object.entries(this.error).forEach(([, value]) => {
      if (value) {
        result = value;
      }
    });
    return result;
  }

  get clientType() {
    if (this.detailClient.clientType === "Client Induk") {
      return "induk";
    }
    return "cabang";
  }

  autoFillColumnDisabled = false;
  openFill = false;
  isFill = false;
  autoFill() {
    this.error.codRejPhone = false;
    this.openFill = false;
    this.isFill = !this.isFill;

    if (this.isFill) {
      this.autoFillColumnDisabled = true;
      this.detailClient.clientCodReject.clientCodName = this.modelValue.clientName;
      this.detailClient.clientCodReject.clientCodAddress = this.modelValue.clientAddress;
      this.detailClient.clientCodReject.clientCodDistrict = this.modelValue.clientDistrict;
      this.detailClient.clientCodReject.clientCodPhone = this.modelValue.clientPhone;
    } else {
      this.autoFillColumnDisabled = false;
      this.detailClient.clientCodReject.clientCodName = this.detailClient.clientCodRejectAsDefault.defaultCodName;
      this.detailClient.clientCodReject.clientCodAddress = this.detailClient.clientCodRejectAsDefault.defaultCodAddress;
      this.detailClient.clientCodReject.clientCodDistrict = this.detailClient.clientCodRejectAsDefault.defaultCodDistrict;
      this.detailClient.clientCodReject.clientCodPhone = this.detailClient.clientCodRejectAsDefault.defaultCodPhone;
    }
  }

  validatePhone(value: string) {
    this.error.phone = !/^\d+$/.test(value) || value.length < 8;
    if (this.isFill) {
      this.detailClient.clientCodReject.clientCodPhone = value;
    }
  }

  fullFilledCodDistrict(obj: any) {
    if (this.isFill) {
      this.detailClient.clientCodReject.clientCodDistrict = obj;
    }
  }

  get isCodMandatory() {
    if (this.modelValue.clientIsCod && this.clientType === "cabang") {
      return (
        this.detailClient.clientCodReject.clientCodName !== "" &&
        this.detailClient.clientCodReject.clientCodAddress !== "" &&
        this.detailClient.clientCodReject.clientCodDistrict !== "" &&
        this.detailClient.clientCodReject.clientCodPhone !== "" &&
        this.detailClient.clientCodReject.clientCodCommissionPercentage !== "" &&
        !this.errorCodCommission.error
      );
    } else {
      return true;
    }
  }

  fullFilledCodReceiver(value: string) {
    if (this.isFill) {
      this.detailClient.clientCodReject.clientCodName = value;
    }
  }

  fullFilledCodAddressReceiver(value: string) {
    if (this.isFill) {
      this.detailClient.clientCodReject.clientCodAddress = value;
    }
  }

  get errorClientCodShipmentDiscount() {
    let errorCaption = "";
    const clientCodShipmentDiscount = this.parseToNumber(
      this.detailClient.clientCodShipmentDiscountPercentage.toString()
    );
    if (clientCodShipmentDiscount < 0) {
      errorCaption = "Persentase Diskon COD tidak boleh nol/kosong";
    } else if (clientCodShipmentDiscount > 30) {
      errorCaption = "Persentase Diskon COD tidak boleh lebih dari 30%";
    }
    return {
      error: !!errorCaption,
      caption: errorCaption
    };
  }

  get errorCodCommission() {
    let errorCaption = "";
    const codCommission = this.parseToNumber(
      this.detailClient.clientCodReject.clientCodCommissionPercentage.toString()
    );
    if (codCommission < 0) {
      errorCaption = "Biaya COD tidak boleh kosong";
    } else if (codCommission > 100) {
      errorCaption = "Biaya COD tidak boleh lebih dari 100%";
    }
    return {
      error: !!errorCaption,
      caption: errorCaption
    };
  }

  get errorPadFee() {
    let errorCaption = "";
    const padFee = this.parseToNumber(
      this.detailClient.clientPadFeePercentage.toString()
    );
    if (padFee < 0) {
      errorCaption = "Biaya PAD tidak boleh kosong";
    } else if (padFee > 100) {
      errorCaption = "Biaya PAD tidak boleh lebih dari 100%";
    }
    return {
      error: !!errorCaption,
      caption: errorCaption
    };
  }

  validateCodRejPhone(value: string) {
    this.error.codRejPhone = !/^\d+$/.test(value) || value.length < 8;
  }

  getBeneficiaryAccount() {
    BeneficiaryAccountController.getBeneficiaryAccList({
      page: 1,
      limit: 1,
      bankAccType: "beneficiary",
      actortype: "client",
      actorId: this.modelValue.clientId
    });
  }

  formatFloat(value: string) {
    return value
      .replace(/[^0-9.,]/, "")
      .replace(/\./g, ",")
      .replace(/,,/g, ",")
      .replace(/,\./g, ",")
      .replace(/,(\d+),/g, ",$1");
  }

  parseToNumber(str: string) {
    return parseInputFloatToFloat(str);
  }

  //company size
  companySizeList = [
    {
      name: "Pilih company size",
      value: ""
    },
    {
      name: "Small",
      value: "Small"
    },
    {
      name: "Medium",
      value: "Medium"
    },
    {
      name: "Big",
      value: "Big"
    },
    {
      name: "Micro",
      value: "Micro"
    }
  ];

  isOpenCompanySize = false;
  onOpenSelectCompanySize() {
    this.isOpenCompanySize = true;
  }

  onCloseSelectCompanySize() {
    this.isOpenCompanySize = false;
  }

  onSelectCompanySize(key: string, value: string) {
    this.onCloseSelectCompanySize();
    this.modelValue.clientCompanySize = value;
  }

  get isFormValidTaxSection() {
    let res: any = true;
    if (this.modelValue.clientTax.isTaxPayer) {
      res = this.modelValue.clientTax.NIK;
    }
    return (
      this.modelValue.clientTax.NIKName &&
      this.modelValue.clientTax.NIKAddress &&
      this.modelValue.clientTax.taxNumber &&
      res
    );
  }

  get isFormValidBillingSection() {
    return (
      this.modelValue.clientBilling.noWhatsApp &&
      this.modelValue.clientBilling.billingSchedule &&
      this.modelValue.clientBilling.timeRangePayment &&
      this.modelValue.clientBilling.email.length &&
      this.modelValue.clientBilling.startDate &&
      this.modelValue.clientBilling.endDate &&
      (this.modelValue.clientBilling.attachment ||
        this.modelValue.clientBilling.attachmentUrl)
    );
  }

  handlerClose() {
    this.$emit("resetData");
    this.showCloseConfirmModal = false;
  }

  get isClientParent(): boolean {
    return this.clientType === "induk";
  }

  get clientCodShipmentDiscountTootltipDescription(): string {
    return "<ol class='ml-4 text-12px text-black-lp-300 number list-decimal'> <li> Klien akan dibebankan biaya booking STT COD sebesar <span class='font-bold'>ongkir klien setelah diskon.</span> </li> <li> Tipe COD amount harga barang + ongkir akan menggunakan <span class='font-bold'>ongkir klien sebelum diskon</span> </li> </ol>";
  }
  get clientPaymentMethodData(): OptionsClass[] {
    return clientPaymentMethodData;
  }
  get clientCodConfigAmountDataName() {
    return clientCodConfigAmountData.map((key: any)=> key.name);
  }
  get clientCodConfigAmountDataValue() {
    return clientCodConfigAmountData.map((key: any)=> key.value);
  }

  errorFormCommision = {
    error: false,
    errorCaption: ""
  }
  validateErrorcommission(value: string) {
    const newValue = value === "" ? "" : Number(value.replace(/,/g, "."));
    if (newValue > 100 && newValue !== "") {
      this.errorFormCommision.error = true;
      this.errorFormCommision.errorCaption = "Komisi POS tidak boleh lebih dari 100%";
    } else {
      this.errorFormCommision.error = false;
      this.errorFormCommision.errorCaption = "";
    }
  }
}
