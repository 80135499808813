
import { Options, Vue } from "vue-class-component";

@Options({
  components: {}
})
export default class Client extends Vue {
  // Tabs
  tabs = [
    { name: "client-registration", title: "Registrasi Client" },
    { name: "client-management", title: "Manajemen Client" }
  ];

  changeTabs(value: string) {
    this.$router.push({
      name: value
    });
  }

  isTabActive(name: string) {
    return name === this.$route.name;
  }
}
