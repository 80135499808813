
import { Options, Vue } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import Tooltip from "@/app/ui/components/tooltip/index.vue";
import convertPhoneNumber from "@/app/infrastructures/misc/common-library/ConvertPhoneNumber";
import formattedNPWP from "@/app/infrastructures/misc/common-library/FormattedNPWP";
import { ClientRegistrationController } from "@/app/ui/controllers/ClientRegistrationController";
import debounce from "lodash/debounce";
import CardSelector from "@/app/ui/components/card-selector/index.vue";
import UploadFile from "@/app/ui/components/upload-file/index.vue";
import Checkbox from "primevue/checkbox";
import { PosController } from "@/app/ui/controllers/PosController";
import BankSection from "./bank-section.vue";
import ModalForm from "@/app/ui/components/modal-form/index.vue";
import { RequestListPartner } from "@/data/payload/api/PartnerApiRequest";
import TaxSection from "../../component/tax-section.vue";
import BillingSection from "../../component/billing-section.vue";
import BankSettlementSection from "../../component/bank-settlement-section.vue";
import { EditClientManagementBasicInformationRequest } from "@/data/payload/api/ClientManagementApiRequest";
import { startCase } from "lodash";
import convertArrayToString from "@/app/infrastructures/misc/common-library/ConvertArrayToString";
import formatDateNumber from "@/app/infrastructures/misc/common-library/FormatDateNumber";
import { OptionsClass } from "@/domain/entities/MainApp";
import {
  clientPaymentMethodData,
  clientCodConfigAmountData
} from "@/domain/entities/ClientRegistration";
import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";
import DetailDeliveryOrder from "@/app/ui/views/sales/clients/client-registration/detail/detail-delivery-order.vue";
import LpRadio from "@/app/ui/components/lp-radio/index.vue";

@Options({
  components: {
    DetailLayout,
    Title,
    Tooltip,
    ModalForm,
    CardSelector,
    UploadFile,
    Checkbox,
    BankSection,
    TaxSection,
    BillingSection,
    BankSettlementSection,
    DetailDeliveryOrder,
    LpRadio
  },
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      required: true
    }
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (!this.modalSuccess && !this.isErrorDocument) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      ClientRegistrationController.setErrorDocument(false);
      ClientRegistrationController.setOpenModalSuccess(false);
      next();
    }
  }
})
export default class ModalEditDetail extends Vue {
  get isEnableCodBooking() {
    return FlagsMedusa.config_new_cod_booking_enable.isEnabled();
  }
  get isEnableCodOngkir() {
    return FlagsMedusa.config_cod_ongkir_enable.isEnabled();
  }
  onClose() {
    this.isEditTerminated = true;
  }
  isValidated = true;
  toolTipDiscount = false;
  isEditTerminated = false;
  newPath = "";
  answer = false;
  archiveFile: any = "";

  get clientType() {
    if (this.detailClient.clientType === "Client Induk") {
      return "induk";
    }
    return "cabang";
  }

  error = {
    companyName: false,
    email: false,
    phone: false,
    address: false,
    taxNumber: false,
    versionBasic: false,
    discount: false,
    priceRateStartDate: false,
    priceRateEndDate: false,
    versionName: false,
    archiveFile: false,
    accountBankNo: false,
    bankEmail: false,
    contactEmail: false,
    contactPhone: false,
    commission: false,
    codRejdistrict: false,
    codRejCommision: false,
    codRejPhone: false,
    emailBilling: false,
    NIKNumber: false,
    contactWhatsApp: false,
    invalidDate: false,
    padCommision: false,
    codShipmentDiscount: false
  };

  customPriceOption = [
    {
      label: "Default Basic Rate",
      value: false,
      description:
        "Harga akan menyesuaikan dengan versi harga basic yang tersedia."
    },
    {
      label: "Custom Rate",
      value: true,
      description:
        "Versi harga basic dengan custom harga untuk rute-rute tertentu"
    }
  ];

  get errorCause() {
    return ClientRegistrationController.isErrorCause;
  }

  get isError() {
    return ClientRegistrationController.isError;
  }

  get isFormValid() {
    let result = false;
    const isClientParentFormValid =
      this.clientType === "induk"
        ? this.detailClient.clientCompanySize &&
          this.isFormValidTaxSection &&
          this.isFormValidBillingSection &&
          this.detailClient.clientPaymentMethod.value
        : true;

    if (
      this.detailClient.clientName &&
      this.detailClient.clientAddress &&
      this.detailClient.clientDistrict &&
      this.detailClient.clientEmail &&
      this.detailClient.clientPhone &&
      this.isCodMandatory() &&
      isClientParentFormValid
    ) {
      result = true;
      if (this.detailClient.clientPartner.clientPartnerId) {
        result = !!(
          this.detailClient.clientPartner.clientPartnerId &&
          this.detailClient.clientPartner.clientPartnerCommissionAmount.toString() &&
          this.detailClient.clientPartner.clientPartnerQuantifierCommission &&
          this.detailClient.clientPartner.clientPartnerNumeratorCommision !==
            "-" &&
          this.detailClient.clientPartner.clientPartnerNumeratorCommision !== ""
        );
      }
    }

    const isDO = this.detailClient.clientIsDo ? !!this.detailClient.clientBranchDoPaymentType : true;
    return result && !this.anyFormError && isDO;
  }

  get anyFormError() {
    let result = false;
    Object.entries(this.error).forEach(([, value]) => {
      if (value) {
        result = value;
      }
    });
    return result;
  }

  get isFormValidTaxSection() {
    let res = true;
    if (this.detailClient.clientTax.isTaxPayer) {
      res = this.detailClient.clientTax.NIK;
    }
    return (
      this.detailClient.clientTax.NIKName &&
      this.detailClient.clientTax.NIKAddress &&
      this.detailClient.clientTax.taxNumber &&
      res
    );
  }

  get isFormValidBillingSection() {
    return (
      this.detailClient.clientBilling.noWhatsApp &&
      this.detailClient.clientBilling.billingSchedule &&
      this.detailClient.clientBilling.timeRangePayment &&
      this.detailClient.clientBilling.email.length &&
      this.detailClient.clientBilling.startDate &&
      this.detailClient.clientBilling.endDate &&
      (this.detailClient.clientBilling.attachment ||
        this.detailClient.clientBilling.attachmentUrl)
    );
  }

  mounted() {
    this.fetchDistrictList("");
    this.filterPOS("");
  }

  onOpenClosePage(to: any) {
    this.isEditTerminated = true;
    this.newPath = to.path;
  }

  handlerClose() {
    this.isEditTerminated = false;
    this.answer = true;
    this.$router.push(this.newPath);
    this.$emit("update:modelValue", false);
    this.$emit("resetData");
  }

  handlerCancelClose() {
    this.answer = false;
    this.isEditTerminated = false;
  }

  get contractStartEdit(): any {
    return ClientRegistrationController.contractStartEdit;
  }

  get contractEndEdit(): any {
    return ClientRegistrationController.contractEndEdit;
  }

  get modalSave() {
    return ClientRegistrationController.openModal;
  }

  get isErrorEdit() {
    return ClientRegistrationController.isErrorEdit;
  }

  get messageSuccess() {
    return ClientRegistrationController.messageSuccess;
  }

  // Detail Data
  get detailClient(): any {
    return ClientRegistrationController.clientRegistrationDetail;
  }

  get bankDetail(): any {
    return ClientRegistrationController.bankDetail;
  }

  get bankDetailSettlement(): any {
    return ClientRegistrationController.bankDetailSettlement;
  }

  formatNumber(value: string) {
    const newValue = value.replace(/^62|^0|[\D]+/g, "");
    return newValue.startsWith("0")
      ? newValue.slice(1, newValue.length)
      : newValue;
  }

  formatFloat(value: string) {
    return value
      .replace(/[^0-9.,]/, "")
      .replace(/\./g, ",")
      .replace(/,,/g, ",")
      .replace(/,\./g, ",")
      .replace(/,(\d+),/g, ",$1");
  }

  formatted(value: string) {
    return formattedNPWP(value.replace(/[\D]+/g, ""));
  }

  validateEmail(value: string) {
    this.error.email = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
  }

  validatePhone(value: string) {
    this.error.phone = !/^\d+$/.test(value) || value.length < 8;
    if (this.isFill) {
      this.detailClient.clientCodReject.clientCodPhone = value;
    }
  }

  validateContactEmail(value: string) {
    this.error.contactEmail = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
  }

  validateContactPhone(value: string) {
    this.error.contactPhone = !/^\d+$/.test(value);
  }

  validateTaxNumber(value: string) {
    this.error.taxNumber = !/[0-9-.]+/.test(value);
    if (value.length < 20) {
      this.error.taxNumber = true;
    }
  }

  errorCaptionCommision = "";
  validateCommision(value: string) {
    value = value.replace(/,/g, ".");
    if (Number(value) < 0) {
      this.error.commission = true;
      this.errorCaptionCommision = "Komisi tidak boleh nol/kosong";
    } else if (Number(value) > 100) {
      this.error.commission = true;
      this.errorCaptionCommision = "Komisi tidak boleh lebih dari 100%";
    } else {
      this.error.commission = false;
    }
  }

  startDateSelected(value: Date) {
    ClientRegistrationController.setContractStartEdit(value);
    const secondDate = new Date(this.contractEndEdit);
    this.error.priceRateStartDate =
      value.getTime() > secondDate.getTime() ||
      value.getTime() === secondDate.getTime();
  }

  endDateSelected(value: any) {
    ClientRegistrationController.setContractEndEdit(value);
    const secondDate = new Date(this.contractStartEdit);
    this.error.priceRateEndDate =
      value.getTime() < secondDate.getTime() ||
      value.getTime() === secondDate.getTime();
  }

  openSave() {
    return this.modalSave;
  }

  openModalSave() {
    return ClientRegistrationController.setOpenModal(true);
  }

  closeModalSave() {
    return ClientRegistrationController.setOpenModal(false);
  }

  onCloseModalSuccess() {
    this.$router.push(`/client/client-registration/${this.$route.params.id}`);
  }

  async editClient() {
    this.closeModalSave();
    this.closeModalError();
    const payload = new EditClientManagementBasicInformationRequest({
      id: this.detailClient.id,
      companyName: this.detailClient.clientName,
      email: this.detailClient.clientEmail,
      phone: convertPhoneNumber(this.detailClient.clientPhone),
      address: this.detailClient.clientAddress,
      districtCode: this.detailClient.clientDistrict?.code,
      taxNumber:
        this.clientType === "induk"
          ? this.detailClient.clientTax.taxNumber
          : this.detailClient.clientTaxNumber,
      type: this.clientType === "induk" ? "parent" : "branch",
      category: this.detailClient.clientCategory,
      bankName: this.bankDetail.bankName?.value || this.bankDetail.bankName,
      bankAccountName: this.bankDetail.accountName,
      bankAccountNumber: this.bankDetail.accountNo,
      bankAccountEmail: this.bankDetail.email,
      bankAccountLabel: this.bankDetail.bankLabel,
      contactName: this.detailClient.clientContact.clientContactName,
      contactJobTitle: this.detailClient.clientContact.clientContactJobTitle,
      contactEmail: this.detailClient.clientContact.clientContactEmail,
      contactPhone: convertPhoneNumber(
        this.detailClient.clientContact.clientContactPhone
      ),
      partnerPosId: this.detailClient.clientPartner.clientPartnerId,
      partnerPosCommission: Number(
        this.replaceComma(
          `${this.detailClient.clientPartner.clientPartnerCommissionAmount}`
        )
      ),
      partnerPosQuantifierCommission: this.detailClient.clientPartner
        .clientPartnerQuantifierCommission,
      partnerPosNumeratorCommission: this.detailClient.clientPartner
        .clientPartnerNumeratorCommision,
      isDo: this.detailClient.clientIsDo,
      isCod: this.detailClient.clientIsCod,
      isDfod: this.detailClient.clientIsDfod,
      receiverName: this.detailClient.clientCodReject.clientCodName,
      receiverPhone: convertPhoneNumber(
        this.detailClient.clientCodReject.clientCodPhone
      ),
      receiverAddress: this.detailClient.clientCodReject.clientCodAddress,
      receiverCity: this.detailClient.clientCodReject.clientCodDistrict
        .cityCode,
      receiverDistrict: this.detailClient.clientCodReject.clientCodDistrict
        .code,
      codFee: Number(
        this.replaceComma(
          `${this.detailClient.clientCodReject.clientCodCommission}`
        )
      ),
      companySize: this.detailClient.clientCompanySize,
      isEfakturWithNik: this.detailClient.clientTax.isTaxPayer,
      nikId: this.detailClient.clientTax.NIK,
      nikName: this.detailClient.clientTax.NIKName,
      nikAddress: this.detailClient.clientTax.NIKAddress,
      taxpayerName: this.detailClient.clientTax.NIKName,
      taxpayerAddress: this.detailClient.clientTax.NIKAddress,
      billingWaNumber: convertPhoneNumber(
        this.detailClient.clientBilling.noWhatsApp
      ),
      billingEmail: convertArrayToString(
        this.detailClient.clientBilling.email,
        "name"
      ),
      billingScheduleSo: this.detailClient.clientBilling.billingSchedule.startsWith(
        "bi"
      )
        ? "Bi-Weekly"
        : `${startCase(this.detailClient.clientBilling.billingSchedule)}`,
      billingPaymentPeriod: `${this.detailClient.clientBilling.timeRangePayment} Days`,
      contractStartDate: formatDateNumber(
        this.detailClient.clientBilling.startDate
      ),
      contractEndDate: formatDateNumber(
        this.detailClient.clientBilling.endDate
      ),
      vaBankNumber: this.bankDetailSettlement.accountNo ?? "",
      vaBankAccountName: this.bankDetailSettlement.accountName ?? "",
      vaBankName: this.bankDetailSettlement.bankName
        ? this.bankDetailSettlement.bankName?.name ||
          this.bankDetailSettlement.bankName
        : "",
      vaBankLabel: this.bankDetailSettlement.bankName
        ? `${this.bankDetailSettlement.bankName?.name?.toUpperCase() ||
            this.bankDetailSettlement.bankName.toUpperCase()} Virtual Account`
        : "",
      contractAttachment: this.detailClient.clientBilling.attachment,
      isReuploadContract: this.detailClient.clientBilling.attachment
        ? true
        : false,
      clientPadFee: this.detailClient.clientPadFee,
      paymentMethod: this.isClientParent
        ? this.detailClient.clientPaymentMethod?.value
        : "",
      clientCodConfigAmount: this.detailClient.clientCodConfigAmount.value,
      clientCodShipmentDiscount:
        +this.detailClient.clientCodShipmentDiscount.replace(/,/g, ".") || "",
      clientBranchDoPaymentType: this.detailClient.clientBranchDoPaymentType
    });
    const resp = await ClientRegistrationController.editClientManagementBasicInformation(
      payload
    );
    if (resp) {
      this.handlerClose();
    }
  }

  replaceComma(str: string) {
    return str.replace(/,/g, ".");
  }

  // district list
  fetchDistrictList = debounce((search: string) => {
    ClientRegistrationController.getDistrictList({
      search: search
    });
  }, 250);

  get districtList() {
    return ClientRegistrationController.districtList;
  }

  get loadingDistrictList() {
    return ClientRegistrationController.loadingDistrictList;
  }

  get modalSuccess() {
    return ClientRegistrationController.openModalSuccess;
  }

  goBack() {
    this.$router.push(`/client/client-registration/${this.$route.params.id}`);
  }

  closeModalError() {
    ClientRegistrationController.setErrorEdit(false);
  }

  // Error Document
  get isErrorDocument() {
    return ClientRegistrationController.isErrorDocument;
  }

  // category client
  isOpenClientCategory = false;
  onOpenSelectClientCategory() {
    this.isOpenClientCategory = true;
  }
  onCloseSelectClientCategory() {
    this.isOpenClientCategory = false;
  }
  get clientCategoryList() {
    return [
      {
        name: "Business to Business",
        value: "B2B"
      },
      {
        name: "Business to Customer",
        value: "B2C"
      },
      {
        name: "Customer to Customer",
        value: "C2C"
      },
      {
        name: "Small Medium Business",
        value: "SMB"
      }
    ];
  }
  onChooseSelectClientCategory(name: string, value: string) {
    ClientRegistrationController.setDetailClientCategory(value);
    this.onCloseSelectClientCategory();
  }

  get modelPartnerPos() {
    if (this.detailClient.clientPartner.clientPartnerId !== 0) {
      return {
        id: this.detailClient.clientPartner.clientPartnerId,
        name: this.detailClient.clientPartner.clientPartnerName
      };
    }
    return "";
  }

  onChangePartnerPos(item: any) {
    ClientRegistrationController.setPartnerPos(item);
  }

  // pos
  filterPOS = debounce((search: string) => {
    PosController.getPosList(
      new RequestListPartner({
        page: 1,
        limit: 10,
        search: search,
        cityCode: this.detailClient.clientDistrict.cityCode,
        type: "pos",
        useLocation: true,
        useDefaultLocation: true
      })
    );
  }, 250);

  get posList() {
    return PosController.posList.data.map(e => ({
      id: e.id,
      name: `${e.name}`
    }));
  }

  get isLoadingPOS() {
    return PosController.isLoading;
  }

  commisionTypes = [
    {
      name: "Pilih tipe komisi",
      value: ""
    },
    {
      name: "Shipping surcharge",
      value: "shipping_surcharge"
    },
    {
      name: "Publish Rate",
      value: "publish_rate"
    },
    {
      name: "Publish Rate + Shipping Surcharge",
      value: "publish_rate_shipping_surcharge"
    }
  ];

  get commissionDisabled() {
    return this.modelPartnerPos === "";
  }

  autoFillColumnDisabled = false;
  openFill = false;
  isFill = false;
  autoFill() {
    this.error.codRejPhone = false;
    this.openFill = false;
    this.isFill = !this.isFill;

    if (this.isFill) {
      this.autoFillColumnDisabled = true;
      this.detailClient.clientCodReject.clientCodName = this.detailClient.clientName;
      this.detailClient.clientCodReject.clientCodAddress = this.detailClient.clientAddress;
      this.detailClient.clientCodReject.clientCodDistrict = this.detailClient.clientDistrict;
      this.detailClient.clientCodReject.clientCodPhone = this.detailClient.clientPhone;
    } else {
      this.autoFillColumnDisabled = false;
      this.detailClient.clientCodReject.clientCodName = this.detailClient.clientCodRejectAsDefault.defaultCodName;
      this.detailClient.clientCodReject.clientCodAddress = this.detailClient.clientCodRejectAsDefault.defaultCodAddress;
      this.detailClient.clientCodReject.clientCodDistrict = this.detailClient.clientCodRejectAsDefault.defaultCodDistrict;
      this.detailClient.clientCodReject.clientCodPhone = this.detailClient.clientCodRejectAsDefault.defaultCodPhone;
    }
  }

  isCodMandatory() {
    if (this.detailClient.clientIsCod && this.clientType === "cabang") {
      return (
        this.detailClient.clientCodReject.clientCodName &&
        this.detailClient.clientCodReject.clientCodAddress &&
        this.detailClient.clientCodReject.clientCodDistrict &&
        this.detailClient.clientCodReject.clientCodPhone &&
        this.detailClient.clientCodConfigAmount.value &&
        this.detailClient.clientCodShipmentDiscount
      );
    } else {
      return true;
    }
  }
  get clientCodShipmentDiscountTootltipDescription(): string {
    return "<ol class='ml-4 text-12px text-black-lp-300 number list-decimal'> <li> Klien akan dibebankan biaya booking STT COD sebesar <span class='font-bold'>ongkir klien setelah diskon.</span> </li> <li> Tipe COD amount harga barang + ongkir akan menggunakan <span class='font-bold'>ongkir klien sebelum diskon</span> </li> </ol>";
  }

  fullFilledCodReceiver(value: string) {
    if (this.isFill) {
      this.detailClient.clientCodReject.clientCodName = value;
    }
  }

  fullFilledCodAddressReceiver(value: string) {
    if (this.isFill) {
      this.detailClient.clientCodReject.clientCodAddress = value;
    }
  }

  errorCaptionCodRejCommision = "";
  validateCodRejCommision(value: string) {
    value = value.replace(/,/g, ".");
    if (Number(value) < 0) {
      this.error.codRejCommision = true;
      this.errorCaptionCodRejCommision = "Biaya COD tidak boleh nol/kosong";
    } else if (Number(value) > 100) {
      this.error.codRejCommision = true;
      this.errorCaptionCodRejCommision =
        "Biaya COD tidak boleh lebih dari 100%";
    } else {
      this.error.codRejCommision = false;
    }
  }

  errorCaptionCodShipmentDiscount = "";
  validateCodShipmentDiscount(value: string) {
    value = value.replace(/,/g, ".");
    if (Number(value) < 0) {
      this.error.codShipmentDiscount = true;
      this.errorCaptionCodShipmentDiscount =
        "Persentase Diskon COD tidak boleh nol/kosong";
    } else if (Number(value) > 30) {
      this.error.codShipmentDiscount = true;
      this.errorCaptionCodShipmentDiscount =
        "Persentase Diskon COD tidak boleh lebih dari 30%";
    } else {
      this.error.codShipmentDiscount = false;
      this.errorCaptionCodShipmentDiscount = "";
    }
  }

  errorCaptionPadCommision = "";
  validatePadCommision(value: string) {
    value = value.replace(/,/g, ".");
    if (Number(value) < 0) {
      this.error.padCommision = true;
      this.errorCaptionPadCommision = "Biaya PAD tidak boleh nol/kosong";
    } else if (Number(value) > 100) {
      this.error.padCommision = true;
      this.errorCaptionPadCommision = "Biaya PAD tidak boleh lebih dari 100%";
    } else {
      this.error.padCommision = false;
    }
  }

  validateCodRejPhone(value: string) {
    this.error.codRejPhone = !/^\d+$/.test(value) || value.length < 8;
  }

  // numerator commission
  numeratorCommissionList = [
    {
      name: "Pilih pembilang komisi",
      value: ""
    },
    {
      name: "Harga Retail",
      value: "retail-rate"
    },
    {
      name: "Harga Client",
      value: "client-rate"
    }
  ];

  isOpenNumeratorCommission = false;
  onOpenSelectNumeratorCommission() {
    this.isOpenNumeratorCommission = true;
  }

  onCloseSelectNumeratorCommission() {
    this.isOpenNumeratorCommission = false;
  }

  onSelectNumeratorCommission(key: string, value: string) {
    this.onCloseSelectNumeratorCommission();
    this.detailClient.clientPartner.clientPartnerNumeratorCommision = value;
  }

  get commissionRequired() {
    return this.modelPartnerPos ? true : false;
  }

  isOpenCommissionType = false;
  onOpenSelectCommissionType() {
    this.isOpenCommissionType = true;
  }

  onCloseSelectCommissionType() {
    this.isOpenCommissionType = false;
  }

  onSelectCommissionType(key: string, value: string) {
    this.onCloseSelectCommissionType();
    this.detailClient.clientPartner.clientPartnerQuantifierCommission = value;
  }

  //company size
  companySizeList = [
    {
      name: "Pilih company size",
      value: ""
    },
    {
      name: "Small",
      value: "Small"
    },
    {
      name: "Medium",
      value: "Medium"
    },
    {
      name: "Big",
      value: "Big"
    },
    {
      name: "Micro",
      value: "Micro"
    }
  ];

  isOpenCompanySize = false;
  onOpenSelectCompanySize() {
    this.isOpenCompanySize = true;
  }

  onCloseSelectCompanySize() {
    this.isOpenCompanySize = false;
  }

  onSelectCompanySize(key: string, value: string) {
    this.onCloseSelectCompanySize();
    this.detailClient.clientCompanySize = value;
  }

  get isClientParent(): boolean {
    return this.clientType === "induk";
  }

  get clientPaymentMethodData(): OptionsClass[] {
    return clientPaymentMethodData;
  }

  get clientCodConfigAmountDataName() {
    return clientCodConfigAmountData.map((key: any)=> key.name);
  }

  get clientCodConfigAmountDataValue() {
    return clientCodConfigAmountData.map((key: any)=> key.value);
  }
}
