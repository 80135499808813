
import { Options, Vue } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import {
  checkRolePermission,
  formatDateWithoutTime,
  formatDate,
  convertPhoneNumber
} from "@/app/infrastructures/misc/Utils";
import { CLIENT } from "@/app/infrastructures/misc/RolePermission";
import { ClientRegistrationController } from "@/app/ui/controllers/ClientRegistrationController";
import CreateUpdate from "@/app/ui/components/data-wrapper/create-update.vue";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import { BeneficiaryAccountController } from "@/app/ui/controllers/BeneficiaryAccountController";
import ModalEditDetail from "../component/modal-edit-detail.vue";
import RenewRates from "../../client-management/component/popup-renew-rates.vue";
import { BankDetail } from "@/domain/entities/ClientRegistration";
import { ClientController } from "@/app/ui/controllers/ClientController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import startCase from "lodash/startCase";
import DetailTaxInformation from "@/app/ui/views/sales/clients/client-registration/detail/detail-tax-information.vue";
import DetailBillingInformation from "@/app/ui/views/sales/clients/client-registration/detail/detail-billing-information.vue";
import DetailBankSettlementInformation from "@/app/ui/views/sales/clients/client-registration/detail/detail-bank-settlement-information.vue";
import DetailDeliveryOrder from "@/app/ui/views/sales/clients/client-registration/detail/detail-delivery-order.vue";
import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";

@Options({
  components: {
    DetailBankSettlementInformation,
    DetailBillingInformation,
    DetailTaxInformation,
    DetailLayout,
    CreateUpdate,
    Title,
    ModalEditDetail,
    RenewRates,
    DetailDeliveryOrder
  },
  computed: {
    isEditAble() {
      const clientStatus =
        this.type === "Induk"
          ? this.detailData.clientCurrentRate.clientStatusCustomRate.toLowerCase() ===
              "requested" ||
            this.detailData.clientCurrentRate.clientStatusCustomRate.toLowerCase() ===
              "rejected"
          : this.detailData.clientStatus.toLowerCase() === "waiting" ||
            this.detailData.clientStatus.toLowerCase() === "rejected";
      return checkRolePermission(CLIENT.REGISTRATION_EDIT) && clientStatus;
    },
    isApproveAble() {
      const clientStatus =
        this.type === "Induk"
          ? this.detailData.clientCurrentRate.clientStatusCustomRate.toLowerCase() ===
            "requested"
          : this.detailData.clientStatus.toLowerCase() === "waiting";
      return checkRolePermission(CLIENT.REGISTRATION_APPROVAL) && clientStatus;
    }
  }
})
export default class DetailClient extends Vue {
  get isEnableCodBooking() {
    return FlagsMedusa.config_new_cod_booking_enable.isEnabled();
  }
  get isEnableCodOngkir() {
    return FlagsMedusa.config_cod_ongkir_enable.isEnabled();
  }
  get isElexys() {
    return FlagsMedusa.config_extra_receipt.isEnabled();
  }

  // misc variable
  approvalConfirmation = false;
  convertPhoneNumber: any = convertPhoneNumber;
  get approvalSuccess() {
    return ClientRegistrationController.openModalSuccess;
  }
  rejectConfirmation = false;
  rejectToEditConfirmation = false;
  openSelect = false;
  titleSuccess = "";
  messageSuccess = "";
  rejectedReasons = [
    {
      name: "Pilih alasan penolakan",
      value: ""
    },
    {
      name: "Diskon Tidak Disetujui",
      value: "Diskon Tidak Disetujui"
    },
    {
      name: "Custom Rates Tidak Disetujui",
      value: "Custom Rates Tidak Disetujui"
    },
    {
      name: "Diskon & Custom Rate Tidak Disetujui",
      value: "Diskon & Custom Rate Tidak Disetujui"
    }
  ];
  selectedReason = "";

  get id(): number {
    return Number(this.$route.params.id);
  }

  // Get Data
  created() {
    this.getDetail();
  }

  getDetail() {
    ClientRegistrationController.getDetail(this.id);
  }

  get beneficiaryDetail() {
    return BeneficiaryAccountController.beneficiaryList.data;
  }

  get clientCategory() {
    if (this.detailData.clientCategory === "B2B") {
      return "Business to Business";
    } else if (this.detailData.clientCategory === "B2C") {
      return "Business to Customer";
    } else if (this.detailData.clientCategory === "C2C") {
      return "Customer to Customer";
    } else {
      return "Small Medium Business";
    }
  }

  get idClient() {
    return String(this.detailData.id);
  }

  get posCommision() {
    return String(this.detailData.clientPartner.clientPartnerCommissionAmount);
  }

  // Detail Data
  get detailData() {
    return ClientRegistrationController.clientRegistrationDetail;
  }
  get clientStatus() {
    return ClientRegistrationController.clientStatus;
  }

  get convertCreatedAt() {
    return formatDateWithoutTime(this.detailData.clientCreatedAt);
  }

  get convertUpdatedAt() {
    return formatDateWithoutTime(this.detailData.clientUpdatedAt);
  }

  get basicRatePeriod() {
    return `${formatDateWithoutTime(
      this.detailData.clientCurrentRate.clientRateVersionStartDate
    )} - ${formatDateWithoutTime(
      this.detailData.clientCurrentRate.clientRateVersionEndate
    )}`;
  }

  get customRatePeriod() {
    return `${formatDateWithoutTime(new Date())} - ${formatDateWithoutTime(
      new Date()
    )}`;
  }

  get rejectedBy() {
    return this.detailData.clientStatusUpdatedBy
      ? this.detailData.clientStatusUpdatedBy
      : this.detailData.clientUpdatedBy;
  }

  get rejectedAt() {
    return this.detailData.clientStatusUpdatedAt
      ? formatDate(this.detailData.clientStatusUpdatedAt)
      : formatDate(this.detailData.clientUpdatedAt);
  }

  // Loading
  get isLoading() {
    return ClientRegistrationController.isLoading;
  }

  // route navigation
  goBack() {
    this.$router.push("/client/client-registration");
  }

  // edit
  isRenewRates = false;
  setRenewRates(value: boolean) {
    this.isRenewRates = value;
  }

  isEditDetail = false;
  isEditRate = false;
  goEdit() {
    if (this.beneficiaryDetail.length) {
      ClientRegistrationController.setBankDetail(
        new BankDetail({
          bankLabel: this.beneficiaryDetail[0]?.bankAccountLabel,
          bankName: this.beneficiaryDetail[0]?.bankName,
          accountNo: this.beneficiaryDetail[0]?.bankAccountNumber,
          accountName: this.beneficiaryDetail[0]?.bankAccountName,
          email: this.beneficiaryDetail[0]?.bankAccountEmail,
          logo: this.beneficiaryDetail[0]?.bankAccountLogo
        })
      );
    } else {
      ClientRegistrationController.setBankDetail(new BankDetail());
    }

    this.isEditDetail = true;
  }

  // Handle Error
  get isError() {
    return ClientRegistrationController.isError;
  }

  get isErrorRenewRates() {
    return ClientRegistrationController.isErrorRenewRates;
  }

  get errorCause() {
    return ClientRegistrationController.isErrorCause;
  }

  get type() {
    if (this.detailData.clientType === "Client Induk") {
      return "Induk";
    } else {
      return "Cabang";
    }
  }

  handleError() {
    ClientRegistrationController.handleError();
  }

  // handle approval
  onApproved() {
    this.approvalConfirmation = false;
    this.messageSuccess = `Permintaan Client “${this.detailData.clientName}” berhasil disetejui`;
    this.titleSuccess = "Permintaan Client Berhasil Disetujui !";
    ClientRegistrationController.approveClientRegistration({ id: this.id });
  }
  openApprovalConfirmation() {
    this.approvalConfirmation = true;
  }
  closeApprovalConfirmation() {
    this.approvalConfirmation = false;
  }
  onApprovalSuccess() {
    ClientRegistrationController.setOpenModalSuccess(false);
    this.goBack();
  }

  get isDisableReject() {
    return this.selectedReason === "";
  }
  onRejected() {
    this.rejectConfirmation = false;
    this.messageSuccess = `Permintaan Client “${this.detailData.clientName}” berhasil ditolak. Alasan penolakan akan terlihat pada detail status aktivitas.`;
    this.titleSuccess = "Permintaan Client Berhasil Ditolak !";
    ClientRegistrationController.rejectClientRegistration({
      id: this.id,
      rejectReason: this.selectedReason
    });
  }
  openRejectConfirmation() {
    this.rejectConfirmation = true;
  }
  closeRejectConfirmation() {
    this.rejectConfirmation = false;
    this.selectedReason = "";
  }

  onRejectToEdit() {
    this.rejectToEditConfirmation = false;
    this.goEdit();
  }
  openRejectToEditConfirmation() {
    this.rejectToEditConfirmation = true;
  }
  closeRejectToEditConfirmation() {
    this.rejectToEditConfirmation = false;
  }

  // fixing confirm
  isConfirmFixingRates = false;
  onConfirmFixingRates(value: boolean) {
    this.isConfirmFixingRates = value;
  }

  get isShowModal() {
    return this.approvalConfirmation || this.isConfirmFixingRates;
  }
  onResetModal() {
    this.closeApprovalConfirmation();
    this.onConfirmFixingRates(false);
    this.setRenewRates(false);
  }
  get confirmationModal() {
    if (this.approvalConfirmation) {
      return {
        title: "Konfirmasi Persetujuan Client",
        message:
          "Sebelum menyetujui permintaan ini, pastikan seluruh data client sudah benar dan sesuai.",
        textSuccess: "Approve",
        onSubmit: this.onApproved
      };
    } else if (this.isConfirmFixingRates) {
      return {
        title: "Konfirmasi Perbaikan",
        message: `Anda yakin ingin memperbaiki harga Client<br/>“${this.detailData.clientName}” ?`,
        textSuccess: "Perbaiki",
        onSubmit: () => {
          this.onConfirmFixingRates(false);
          this.setRenewRates(true);
        }
      };
    }
    return null;
  }

  // tabs
  isRatesTab = false;
  activeTab = "detail-induk-client";
  tabs = [
    {
      name: "detail-induk-client",
      title: "Detail Induk Client",
      isRatesTab: false
    },
    { name: "harga", title: "Pilihan Harga", isRatesTab: true }
  ];

  changeTabs(value: boolean, name: string) {
    this.isRatesTab = value;
    this.activeTab = name;
  }

  isTabActive(name: string) {
    return name === this.activeTab;
  }

  get priceTypeRenewRates() {
    if (
      this.detailData.clientCurrentRate.clientVersionBaseRate &&
      this.detailData.clientCurrentRate.clientVersionCustomRate
    ) {
      return "mix";
    }
    if (
      !this.detailData.clientCurrentRate.clientVersionBaseRate &&
      this.detailData.clientCurrentRate.clientVersionCustomRate
    ) {
      return "custom";
    }
    return "basic";
  }

  async retryUploadClientRate() {
    const resp = await ClientController.retryUploadClientRate({
      clientId: this.detailData.id,
      rateVersionId: this.detailData.clientCurrentRate?.clientCustomRateId || 0
    });
    if (resp) {
      MainAppController.setSnackbarMessage("Upload ulang client rate berhasil");
      MainAppController.setSnackbarTimeout(2000);
      MainAppController.setSnackbar(true);
      this.getDetail();
    }
  }

  get isClientParent(): boolean {
    return this.type === "Induk";
  }

  startCase(str: string) {
    return startCase(str);
  }
}
